@use "../config" as *;
.news-list .preview-swiper .article-list {
    margin-bottom: 0;
}
.news-list {
    .layout-wrapper {
        position: relative;
        padding: 0;
    }
    .article-list {
        @extend %px-3;
    }
    .second-list {
        .article-title {
            --_line: 2;
        }
    }
    .waf-head .title {
        @extend %neutral-50;
    }
    .waf-body {
        @extend %mx-3-neg;
    }
    .article-item {
        @extend %article-item-scale;
    }
    .article-title {
        font-weight: 500;
    }
    .second-list {
        @extend %mt-3;
    }
}
@media screen and (min-width: $tablet-min-width) {
    .news-list {
        .preview-swiper {
            margin-inline: calc(var(--container-white-space) * -1);
            width: calc(100% + (var(--container-white-space) * 2));
        }
        .swiper-slide {
            .article-content {
                display: none;
            }
        }
        .waf-body {
            margin-inline: unset;
        }
        .article-thumbnail::before {
            border-radius: var(--space-2);
        }
        .swiper-slide {
            &-active {
                .article-content {
                    display: flex;
                }
            }
            &-next,
            &-prev {
                img {
                    filter: opacity(50%);
                }
                .img-box {
                    background: clr(neutral-50, 9);
                }
            }
        }
        .preview-swiper .article-list {
            --_line: 3;
            --_fontsize: 20;
            gap: var(--space-3);
            @include listing-card(overlay);
            overflow: unset;
            .article-item {
                width: 50%;
            }
            .article-content {
                background: transparent;
                justify-content: center;
                padding-bottom: 3rem;
                .reaction-section {
                    margin-left: var(--space-6);
                }
            }
            .article-title {
                text-align: center;
                line-height: 3rem;
                margin-bottom: 0;
            }
            .article-meta {
                justify-content: center;
                width: unset;
            }
        }
        .second-list {
            @include listing-card(vertical);
            @include grid(4, var(--space-3));
        }
    }
}